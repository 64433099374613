import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  OutlinedInput,
  styled,
} from '@material-ui/core'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import SearchIcon from '@mui/icons-material/Search'
import RoomIcon from '@mui/icons-material/Room'
import ScheduleIcon from '@mui/icons-material/Schedule'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

import Navbar from '../../../components/navbar'
import MobileNavbar from '../../../components/navbar/mobile'
import { useCart } from '../../../hooks/useCart'
import {
  Competition,
  useLazyGetCompetitionsQuery,
} from '../../../services/api/endpoints/competitions'

const StyledSearchResultText = styled('span')`
  color: #3c3835;
`

const StyledSearchResultItem = styled('div')`
  margin: 15px 0;
`

const StyledSearchResultHeader = styled('div')`
  margin-bottom: 25px;
`

const StyledSearchResultDetails = styled('div')`
  margin-bottom: 40px;
`

const StyledSearchResultFooter = styled('div')`
  color: #3c3835;
`

const ClearFix = styled('div')`
  clear: both;
`

const StyledImageContainer = styled('div')`
  float: right;
`

const StyledImg = styled('img')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        height: '40px',
      },
    }
  }}
  height:50px;
`

const StyledSearchResultHeaderContent = styled('div')`
  float: left;
  color: #3c3835;
`

const StyledFormControl = styled(FormControl)`
  background-color: #fff;
  border-color: white !important;
`

const StyledFormSearchContainer = styled(Box)`
  margin-top: 20px;
  margin-bottom: 40px;
`

const StyledSearchResult = styled(Box)`
  padding: 40px;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 10px;
  font-size: 18px;
  box-shadow: 0px 5px 10px 0px #eee;
`

const StyledSearchResultHeaderTitle = styled('div')`
  font-weight: bold;
  font-size: 20px;
`

const StyledSearchResultHeaderSubtitle = styled('div')`
  font-size: 18px;
  margin-top: 5px;
`

const StyledContainer = styled(Container)`
  margin-top: 60px;
`

const searchResultIconStyles = {
  fill: '#008174',
  marginBottom: '-5px',
  marginRight: '10px',
}

const RegisterTeamVenuePage = (props: PageProps) => {
  const cartType = 'team'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })
  const [filteredCompetitions, setFilteredCompetitions] = useState([])
  const [params] = useState({
    offset: 0,
    limit: 100000,
    search: '',
    currentSeason: true,
    isOpen: true,
  })

  const [trigger, result] = useLazyGetCompetitionsQuery()
  const [search, setSearch] = useState('')
  const { setCompetition } = useCart({ type: cartType })

  const { data: competitions } = result

  useEffect(() => {
    trigger({ ...params, currentSeason: true })
  }, [params])

  useEffect(() => {
    if (search === '') return setFilteredCompetitions([])
    if (competitions) {
      setFilteredCompetitions(filterByValue(competitions, search))
    }
  }, [search, competitions])

  const filterByValue = (array: any, string: string) => {
    return array.filter(
      (o: any) =>
        o.name.toLowerCase().includes(string.toLowerCase()) ||
        o.association.name.toLowerCase().includes(string.toLowerCase()),
    )
  }

  const handleClick = async (competitionId: string) => {
    await setCompetition({ competitionId, type: cartType }).unwrap()
    navigate(`/register/team/details`)
  }

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <StyledContainer>
        <StyledFormSearchContainer>
          <StyledFormControl fullWidth variant="outlined">
            <OutlinedInput
              id="outlined-search"
              type="text"
              placeholder="Search by association or competition name"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={e => setSearch(e.target.value)}
            />
          </StyledFormControl>
        </StyledFormSearchContainer>
        {filteredCompetitions && filteredCompetitions.length ? (
          filteredCompetitions.map((competition: Competition) => (
            <StyledSearchResult key={competition._id}>
              <Box
                onClick={() => handleClick(competition._id)}
                sx={{ cursor: 'pointer' }}
              >
                <StyledSearchResultHeader>
                  <StyledSearchResultHeaderContent>
                    <StyledSearchResultHeaderTitle>
                      {competition.name}
                    </StyledSearchResultHeaderTitle>
                    <StyledSearchResultHeaderSubtitle>
                      {competition.association.name}
                    </StyledSearchResultHeaderSubtitle>
                  </StyledSearchResultHeaderContent>
                  {competition.association.logo && (
                    <StyledImageContainer>
                      <StyledImg src={competition.association.logo} alt="" />
                    </StyledImageContainer>
                  )}
                  <ClearFix />
                </StyledSearchResultHeader>
                <StyledSearchResultDetails>
                  <StyledSearchResultItem>
                    <RoomIcon style={searchResultIconStyles} />
                    <StyledSearchResultText>
                      {competition.venue.address.formatted}
                    </StyledSearchResultText>
                  </StyledSearchResultItem>
                  <StyledSearchResultItem>
                    <ScheduleIcon style={searchResultIconStyles} />
                    <StyledSearchResultText>
                      {competition.dayPlayed}, {competition.time}
                    </StyledSearchResultText>
                  </StyledSearchResultItem>
                  {competition?.startDate &&
                    moment(competition.startDate).isValid() && (
                      <StyledSearchResultItem>
                        <CalendarTodayIcon style={searchResultIconStyles} />
                        <StyledSearchResultText>
                          {moment(competition.startDate).format('Do MMM YYYY')}
                        </StyledSearchResultText>
                      </StyledSearchResultItem>
                    )}
                </StyledSearchResultDetails>
                <StyledSearchResultFooter>
                  Registration close{' '}
                  <strong>
                    {competition?.registrationSettings?.team?.endDate
                      ? moment(
                          competition.registrationSettings.team.endDate,
                        ).format('Do MMM YYYY')
                      : 'TBC'}
                  </strong>
                </StyledSearchResultFooter>
              </Box>
            </StyledSearchResult>
          ))
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <p>NO RESULTS FOUND</p>
            <p>
              Please check your search for any spelling errors or try a
              different search term
            </p>
          </Box>
        )}
      </StyledContainer>
    </>
  )
}

export default withAuthenticationRequired(RegisterTeamVenuePage)
